import React, {
  FC,
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { StoreLocatorContext } from "../index";
import { IStoreFe } from "models/client/StoreFe";
import axios from "axios";
import useSWR from "swr";
import {
  StoreLocatorContextActions,
  StoreLocatorContextChangeClickedStoreAction,
  StoreLocatorContextChangeSelectedStoreAction,
  StoreLocatorContextChangeUserStoreAction,
  StoreLocatorContextInitializeAction,
  StoreLocatorContextSetHorizontalWidthAction,
  StoreLocatorContextSetListStoresAction,
  StoreLocatorContextSetMapCenterAction,
  StoreLocatorContextSetMapStoresAction,
  StoreLocatorContextSetMapZoomAction,
  StoreLocatorContextSetStoreArticlesAction,
  StoreLocatorContextState,
  StoreLocatorContextType,
} from "../storeLocatorContext.interfaces";
import LatLngLiteral = google.maps.LatLngLiteral;
import { IArticleFe } from "models/client/ArticleFe";
import { useLocalStorageStore } from "hooks/useLocalStorageStore";
import { PageDataContext } from "../../PageDataContext";

type StoreLocatorContextProviderProps = {
  store?: IStoreFe;
  userStore?: IStoreFe;
  storeArticles?: IArticleFe[];
};

const defaultState: StoreLocatorContextState = {
  center: {
    lat: 45.08704,
    lng: 7.669433,
  },
  zoom: 14,
  horizontalWidth: 0,
  iStoresMap: [],
  iStoresList: [],
  storeArticles: [],
  selectedStore: null,
  clickedStore: null,
  userStore: null,
};

const fetcher = (url: string) =>
  axios
    .get(url, {
      headers: {
        "X-Custom-Origin": window.location.origin,
      },
    })
    .then((res) => res.data.stores);
const singleStoreFetcher = (url: string) =>
  axios
    .get(url, {
      headers: {
        "X-Custom-Origin": window.location.origin,
      },
    })
    .then((res) => res.data.store);

const storeLocatorReducer = (
  state: typeof defaultState,
  action: { type: StoreLocatorContextActions; payload: any }
): typeof defaultState => {
  const { type } = action;

  switch (type) {
    case StoreLocatorContextActions.INITIALIZE:
      const { payload: intialStoresList } =
        action as StoreLocatorContextInitializeAction;

      return {
        ...state,
        iStoresMap: intialStoresList,
        iStoresList: intialStoresList,
      };
    case StoreLocatorContextActions.CHANGE_USER_STORE:
      const { payload: userStore } =
        action as StoreLocatorContextChangeUserStoreAction;

      return {
        ...state,
        userStore,
      };
    case StoreLocatorContextActions.CHANGE_SELECTED_STORE:
      const { payload: selectedStore } =
        action as StoreLocatorContextChangeSelectedStoreAction;

      return {
        ...state,
        selectedStore,
      };
    case StoreLocatorContextActions.CHANGE_CLICKED_STORE:
      const { payload: clickedStore } =
        action as StoreLocatorContextChangeClickedStoreAction;

      return {
        ...state,
        clickedStore,
      };
    case StoreLocatorContextActions.SET_STORE_ARTICLES:
      const { payload: storeArticles } =
        action as StoreLocatorContextSetStoreArticlesAction;
      return { ...state, storeArticles };
    case StoreLocatorContextActions.SET_LIST_STORES:
      const { payload: iStoresList } =
        action as StoreLocatorContextSetListStoresAction;

      return {
        ...state,
        iStoresList,
      };
    case StoreLocatorContextActions.SET_MAP_STORES:
      const { payload: iStoresMap } =
        action as StoreLocatorContextSetMapStoresAction;

      return {
        ...state,
        iStoresMap,
      };
    case StoreLocatorContextActions.SET_MAP_CENTER:
      const { payload: center } =
        action as StoreLocatorContextSetMapCenterAction;

      return {
        ...state,
        center,
      };
    case StoreLocatorContextActions.SET_MAP_ZOOM:
      const { payload: zoom } = action as StoreLocatorContextSetMapZoomAction;

      return {
        ...state,
        zoom,
      };
    case StoreLocatorContextActions.SET_HORIZONTAL_WIDTH:
      const { payload: horizontalWidth } =
        action as StoreLocatorContextSetHorizontalWidthAction;

      return {
        ...state,
        horizontalWidth,
      };
  }
};

export const StoreLocatorContextProvider: FC<
  PropsWithChildren<StoreLocatorContextProviderProps>
> = memo(({ children, store, userStore, storeArticles }) => {
  const [storeLocatorState, dispatchStoreLocatorAction] = useReducer(
    storeLocatorReducer,
    {
      ...defaultState,
      userStore: userStore,
      selectedStore: userStore ? userStore : store,
      clickedStore: userStore ? userStore : store,
      storeArticles: storeArticles,
    }
  );
  const { userProfile } = useContext(PageDataContext);

  const { localStorageStore: localStorageStoreData, setLocalStorageStore } =
    useLocalStorageStore();

  const { data } = useSWR(
    `/api/stores?lat=${storeLocatorState.center.lat}&lon=${storeLocatorState.center.lng}&zoom=${storeLocatorState.zoom}`,
    fetcher
  );

  const { data: localStorageStore2 } = useSWR<IStoreFe>(
    `/api/stores/${localStorageStoreData.storeId}`,
    singleStoreFetcher,
    {
      isPaused: () => !localStorageStoreData.storeId,
    }
  );

  const localStorageStore = useMemo(
    () => localStorageStore2 ?? { storeId: null },
    [localStorageStore2]
  );

  const onStoreSelected = useCallback(
    (store: IStoreFe) => {
      dispatchStoreLocatorAction({
        type: StoreLocatorContextActions.CHANGE_SELECTED_STORE,
        payload: store,
      });

      if (!userProfile || !userProfile.email) {
        // Update local storage only if not logged in
        setLocalStorageStore({
          storeId: store.storeId,
          storeMethod: "Manual",
        });
      }
    },
    [dispatchStoreLocatorAction, setLocalStorageStore, userProfile]
  );

  const setStoreArticles = useCallback(
    (storeArticles: IArticleFe[]) => {
      dispatchStoreLocatorAction({
        type: StoreLocatorContextActions.SET_STORE_ARTICLES,
        payload: storeArticles,
      });
    },
    [dispatchStoreLocatorAction]
  );

  const onUserStoreSelected = useCallback(
    (store: IStoreFe) => {
      dispatchStoreLocatorAction({
        type: StoreLocatorContextActions.CHANGE_USER_STORE,
        payload: store,
      });
    },
    [dispatchStoreLocatorAction]
  );

  const onNearStoreFound = useCallback(
    (store: IStoreFe) => {
      if (!userStore) {
        dispatchStoreLocatorAction({
          type: StoreLocatorContextActions.CHANGE_CLICKED_STORE,
          payload: store,
        });
        dispatchStoreLocatorAction({
          type: StoreLocatorContextActions.CHANGE_SELECTED_STORE,
          payload: store,
        });
      }
    },
    [dispatchStoreLocatorAction, userStore]
  );

  const onStoreClicked = useCallback(
    (store: IStoreFe) => {
      dispatchStoreLocatorAction({
        type: StoreLocatorContextActions.CHANGE_CLICKED_STORE,
        payload: store,
      });
    },
    [dispatchStoreLocatorAction]
  );

  const searchByUserLocation = useCallback(
    async (center: LatLngLiteral, zoom: number) => {
      const { lat, lng } = center;
      const data: IStoreFe[] = await fetcher(
        `/api/stores?lat=${lat}&lon=${lng}&zoom=${zoom}`
      );

      dispatchStoreLocatorAction({
        type: StoreLocatorContextActions.SET_MAP_STORES,
        payload: data,
      });
      dispatchStoreLocatorAction({
        type: StoreLocatorContextActions.SET_LIST_STORES,
        payload: data,
      });
      return data;
    },
    [dispatchStoreLocatorAction]
  );

  const searchByLocation = useCallback(
    async (center: LatLngLiteral, zoom: number) => {
      const { lat, lng } = center;
      const data: IStoreFe[] = await fetcher(
        `/api/stores?lat=${lat}&lon=${lng}&zoom=${zoom}`
      );

      dispatchStoreLocatorAction({
        type: StoreLocatorContextActions.SET_MAP_STORES,
        payload: data,
      });
    },
    [dispatchStoreLocatorAction]
  );

  const searchByName = useCallback(
    async (storeName: string) => {
      const data: IStoreFe[] = await fetcher(
        `/api/stores?storeName=${storeName}`
      );

      dispatchStoreLocatorAction({
        type: StoreLocatorContextActions.SET_LIST_STORES,
        payload: data,
      });
    },
    [dispatchStoreLocatorAction]
  );

  const onCenterChanged = useCallback(
    (center: LatLngLiteral) => {
      dispatchStoreLocatorAction({
        type: StoreLocatorContextActions.SET_MAP_CENTER,
        payload: center,
      });
    },
    [dispatchStoreLocatorAction]
  );

  const onZoomChanged = useCallback(
    (zoom: number) => {
      dispatchStoreLocatorAction({
        type: StoreLocatorContextActions.SET_MAP_ZOOM,
        payload: zoom,
      });
    },
    [dispatchStoreLocatorAction]
  );

  const onHorizontalWidthChanged = useCallback(
    (horizontalWidth: number) => {
      dispatchStoreLocatorAction({
        type: StoreLocatorContextActions.SET_HORIZONTAL_WIDTH,
        payload: horizontalWidth,
      });
    },
    [dispatchStoreLocatorAction]
  );

  const storeLocatorContext: StoreLocatorContextType = useMemo(
    () => ({
      center: storeLocatorState.center,
      zoom: storeLocatorState.zoom,
      horizontalWidth: storeLocatorState.horizontalWidth,
      iStoresMap: storeLocatorState.iStoresMap,
      iStoresList: storeLocatorState.iStoresList,
      selectedStore: storeLocatorState.selectedStore,
      clickedStore: storeLocatorState.clickedStore,
      userStore: storeLocatorState.userStore,
      storeArticles: storeLocatorState.storeArticles,
      onStoreSelected,
      onStoreClicked,
      onNearStoreFound,
      searchByLocation,
      searchByUserLocation,
      searchByName,
      onCenterChanged,
      onZoomChanged,
      onHorizontalWidthChanged,
      onUserStoreSelected,
      setStoreArticles,
    }),
    [
      storeLocatorState,
      onStoreSelected,
      onStoreClicked,
      onNearStoreFound,
      searchByLocation,
      searchByUserLocation,
      searchByName,
      onCenterChanged,
      onZoomChanged,
      onHorizontalWidthChanged,
      onUserStoreSelected,
      setStoreArticles,
    ]
  );

  useEffect(() => {
    dispatchStoreLocatorAction({
      type: StoreLocatorContextActions.INITIALIZE,
      payload: data as IStoreFe[],
    });
  }, [data, dispatchStoreLocatorAction]);

  useEffect(() => {
    if (userStore) {
      dispatchStoreLocatorAction({
        type: StoreLocatorContextActions.CHANGE_USER_STORE,
        payload: userStore,
      });
    }
  }, [userStore, dispatchStoreLocatorAction]);

  useEffect(() => {
    // If the user is not logged in and there is some data in local storage
    if (localStorageStore?.storeId && !userStore) {
      singleStoreFetcher(`/api/stores/${localStorageStore.storeId}`).then(
        (data: IStoreFe) => {
          dispatchStoreLocatorAction({
            type: StoreLocatorContextActions.CHANGE_SELECTED_STORE,
            payload: data,
          });
          dispatchStoreLocatorAction({
            type: StoreLocatorContextActions.CHANGE_CLICKED_STORE,
            payload: data,
          });
        }
      );
    }
  }, [localStorageStore, userStore, dispatchStoreLocatorAction]);

  return (
    <StoreLocatorContext.Provider value={storeLocatorContext}>
      {children}
    </StoreLocatorContext.Provider>
  );
});

StoreLocatorContextProvider.displayName = "StoreLocatorContextProvider";
